<template>
  <router-link v-if="link" :to="link" :style="$attrs.style" style="text-decoration:none">
    <v-icon v-bind="$attrs" color="#00cccc" large> mdi-chevron-left </v-icon>
  </router-link>
  <a v-else :style="$attrs.style" @click="$router.go(-1)">
    <v-icon v-bind="$attrs" color="#00cccc" large> mdi-chevron-left </v-icon>
  </a>
</template>

<script>
export default {
  name: "BackLink",
  props: ['link'],
  data() {
    return {
      backLink: false,
    };
  },
  watch: {
    $route(to, from) {
      if (from.name) {
        this.backLink = true;
      }
      if (this.$route.name == "home") {
        this.backLink = false;
      }
    },
  },
};
</script>
